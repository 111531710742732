import { toDate } from '@/components/helper';

export function companyTransform(item) {
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    return item;
}

export function productTransform(item) {
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    return item;
}

export function roleTransform(item) {
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    return item;
}

export function memberTransform(item) {
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    if (item.birthdate !== null) {
        item.birthdate = toDate(item.birthdate);
    }

    if (item.product && item.product.endContract) {
        item.product.endContract = toDate(item.product.endContract);
    }
    return item;
}

export function userTransform(item) {
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    if (item.birthdate !== null) {
        item.birthdate = toDate(item.birthdate);
    }

    for (let cId in item.companies) {
        item.companies[cId].created_at = toDate(item.companies[cId].created_at);
        item.companies[cId].updated_at = toDate(item.companies[cId].updated_at);

        if (
            item.companies[cId].product &&
            item.companies[cId].product.endContract
        ) {
            item.companies[cId].product.endContract = toDate(
                item.companies[cId].product.endContract,
            );
        }
    }

    return item;
}

export function appointmentTransform(item) {
    item.appointment = toDate(item.appointment);
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    item.notification_at = toDate(item.notification_at);
    item.bookable_from_date = toDate(item.bookable_from_date);

    for (let userId in item.participants) {
        item.participants[userId].created_at = toDate(
            item.participants[userId].created_at,
        );
        item.participants[userId].updated_at = toDate(
            item.participants[userId].updated_at,
        );
        item.participants[userId].canceled_at = toDate(
            item.participants[userId].canceled_at,
        );
    }
    return item;
}

export function contractTransform(item) {
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    item.signingContract = toDate(item.signingContract);
    item.startContract = toDate(item.startContract);
    item.endContract = toDate(item.endContract);
    return item;
}

export function auditTransform(item) {
    item.created_at = toDate(item.created_at);
    if (item.payload.appointment !== undefined) {
        item.payload.appointment = toDate(item.payload.appointment);
    }

    return item;
}

export function billTransform(item) {
    item.billDate = toDate(item.billDate);
    item.created_at = toDate(item.created_at);
    item.updated_at = toDate(item.updated_at);
    return item;
}
