import { useState, useEffect } from 'react';
import Link from 'next/link';
import getAppointmentsAction from '@/components/actions/getAppointmentsAction';
import Loading from '@/components/ui/partials/Loading';
import prepareItemsForList from '@/components/ui/appointment/prepareItemsForList';
import AppointmentListItem from '@/components/ui/appointment/AppointmentListItem';
import { Text, Box } from '@chakra-ui/react';
import _ from 'lodash';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';

export default function AppointmentList({ membership, appointments }) {
    // export default function AppointmentList({ company, user, refresh }) {
    // const [appointments, setAppointments] = useState(null)
    dayjs.extend(localizedFormat);
    dayjs.locale('de');

    // useEffect(() => {
    //     ;(async () => {
    //         setAppointments(
    //             await getAppointmentsAction(
    //                 company.id,
    //                 company?.settings?.hideNotBookableAppointments
    //             )
    //         )
    //     })()
    // }, [refresh, company])

    if (appointments === null) {
        return <Loading />;
    }

    // no appointments exists
    if (!appointments || Object.values(appointments).length === 0) {
        return <Text>Es sind keine Termine angelegt.</Text>;
    }

    // sort / grouping
    let appointmentsData = prepareItemsForList(appointments);

    return (
        <Box mt="5" border="1px" borderColor="gray.50">
            {appointmentsData.map(group => {
                return (
                    <div key={group.title}>
                        <Box bg="gray.50" p="5">
                            {group.title}
                        </Box>
                        {Object.values(group.data).map(item => (
                            // <p key={item.id}>asd</p>
                            <AppointmentListItem
                                key={item.id}
                                item={item}
                                // company={company}
                                // user={user}
                                membership={membership}
                            />
                        ))}
                    </div>
                );
            })}
        </Box>
    );
}
