import { Button } from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

export default function CreateButton({ onClick }) {
    return (
        <Button
            leftIcon={
                <Icon className="fill-current" path={mdiPlus} size={0.9} />
            }
            variant="solid"
            cursor="pointer"
            className="btn-header"
            onClick={onClick}
        >
            Erstellen
        </Button>
    );
}
