import { useQuery } from '@apollo/client';
import { GET_APPOINTMENTS } from '@/components/graphql/appointments';
import { useAuthContext } from './authContext';

export default function useAppointments(filters = {}) {
    let { isAuthenticated } = useAuthContext();
    const { loading, error, data, refetch } = useQuery(GET_APPOINTMENTS, {
        variables: filters,
        skip: !isAuthenticated(),
    });
    let appointments = data ? data?.appointments : undefined;

    return [appointments, loading, refetch, error];
}
