import { useState } from 'react';
import { useRouter } from 'next/router';
import useCompany from '@/components/context/useCompany';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import saveUserSetting from '@/components/actions/saveUserSetting';
import { useAuthContext } from '@/components/context/authContext';
import {
    isDev,
    toastSuccess,
    toastError,
    makeRequest,
} from '@/components/helper';
import { Flex, Button, useToast, Text, Box } from '@chakra-ui/react';
import SettingsField from './fields/SettingsField';
import FormTitle from './fields/FormTitle';
import _ from 'lodash';
import { UPDATE_MEMBERSHIP_SETTINGS } from '@/components/graphql/memberships';
import { useMutation } from '@apollo/client';

export default function CompanyUserSettingsForm({
    membership,
    isLoadingMembership,
    refetchMembership,
}) {
    // prepare
    const router = useRouter();
    const toast = useToast();
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateMembership, { data, loading, error }] = useMutation(
        UPDATE_MEMBERSHIP_SETTINGS,
    );

    let company = membership.companies[0];
    // console.log('membership', membership)
    // console.log('company', company)
    return (
        <>
            <FormTitle>Terminansicht</FormTitle>
            <SettingsField
                id="hide_not_bookable_appointments"
                label="Nicht buchbare Termine ausblenden"
                initValue={membership.hide_not_bookable_appointments}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, updateMembership, membership, settingId, value)
                }
            />
            <FormTitle>Benachrichtigung</FormTitle>

            <SettingsField
                id="enable_reminder_push"
                label="Terminerinnerung via Push Notification"
                initValue={membership.enable_reminder_push}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, updateMembership, membership, settingId, value)
                }
            />
            <SettingsField
                id="enable_reminder_email"
                label="Terminerinnerung via E-Mail"
                initValue={membership.enable_reminder_email}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, updateMembership, membership, settingId, value)
                }
            />
            <SettingsField
                id="enable_waitlist_reminder_push"
                label="Wartelisten-Benachrichtigung via Push N."
                initValue={membership.enable_waitlist_reminder_push}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, updateMembership, membership, settingId, value)
                }
            />
            <SettingsField
                id="enable_waitlist_reminder_email"
                label="Wartelisten-Benachrichtigung via E-Mail"
                initValue={membership.enable_waitlist_reminder_email}
                onAlreadyChanged={(settingId, value) =>
                    save(toast, updateMembership, membership, settingId, value)
                }
            />

            <FormTitle>Anbieter</FormTitle>

            <ConfirmDialog
                isOpen={isOpenDelete}
                onClose={() => setIsOpenDelete(false)}
                action={async () => {
                    setIsOpenDelete(false); // modal
                    setDeleteLoading(true);

                    try {
                        await makeRequest(
                            '/companyMember/removeMembershipRequest',
                            {
                                // company_id: companyId,
                                // master_company_id: company.master_company_id,
                                company_id: company.id,
                                master_company_id: company.master_company_id,
                                subinfo: 'user_delete_company',
                            },
                        );

                        setDeleteLoading(false);
                        refetchMembership();
                        toastSuccess(toast, 'Anbieter wurde gelöscht');
                        router.push('/dashboard');
                    } catch (error) {
                        setDeleteLoading(false);
                        toastError(toast, error);
                    }
                }}
            />

            <Flex mt="1" justify="space-between" align="center">
                <Text mt="1" fontSize="sm" color="gray.500">
                    Du kannst den Anbieter / Mitgliedschaft löschen.
                </Text>

                <Button
                    w="48"
                    colorScheme="red"
                    isLoading={deleteLoading}
                    type="submit"
                    size="xs"
                    variant="outline"
                    onClick={() => setIsOpenDelete(true)}
                >
                    Anbieter Löschen
                </Button>
            </Flex>
        </>
    );
}

async function save(toast, updateMembership, membership, settingId, value) {
    try {
        await updateMembership({
            variables: {
                id: membership.id,
                _set: {
                    [settingId]: value,
                },
            },
        });
        toastSuccess(toast, 'Einstellung wurde gespeichert');
    } catch (error) {
        toastError(toast, error);
    }
}
