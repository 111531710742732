import getEnvironment from '@/components/environments/getEnvironment';
import { toTextDate } from '@/components/helper';
import { Box, Text } from '@chakra-ui/react';

export default function UserStatus({ membership }) {
    let product = membership.product;

    return (
        <Box>
            <Text>{product.title}</Text>
            <Text mt="0" fontSize="sm" color="gray.500">
                {getSubText(membership, product)}
            </Text>
        </Box>
    );
}

function getSubText(membership, product) {
    if (
        product.type === getEnvironment().productTypeENUM.MEMBERSHIP &&
        membership.end_contract !== undefined &&
        membership.end_contract !== null
    ) {
        let endContract = toTextDate(membership.end_contract, 'L', 'de', false);
        return `Vertragsende: ${endContract}`;
    }

    if (
        product.type === getEnvironment().productTypeENUM.FLEXIBLE_TICKET &&
        membership.available_units !== undefined
    ) {
        return `Buchbare Einheiten: ${membership.available_units}`;
    }

    return null;
}
