import getEnvironment from '@/components/environments/getEnvironment';
import { toTextDate } from '@/components/helper';
import { getColorForActionButton } from '@/components/share/AppointmentStatus';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
    mdiCalendarCheck,
    mdiCalendarClock,
    mdiCalendarRemove,
    mdiCalendarText,
} from '@mdi/js';
import Icon from '@mdi/react';
import _ from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function AppointmentListItem({ item, membership }) {
    const router = useRouter();
    let { companyId } = router.query;
    // export default function AppointmentListItem({ item, company, user }) {
    // let time = dayjs(item.appointment).format('LT')
    let time = toTextDate(item.appointment, 'LT', 'de');
    let trainer = item.trainer?.username;

    // let color = 'grey_light'
    let color = getColorForActionButton(
        item,
        membership,
        companyId,
        getEnvironment(),
    );

    return (
        <Link href={`/${companyId}/${item.id}/`}>
            <Flex
                as="a"
                href={`/${companyId}/${item.id}/`}
                borderTop="1px"
                borderColor="gray.200"
                alignContent="center"
                align="center"
                alignItems="stretch"
                role="group"
            >
                <Flex
                    px={[1, 3]}
                    py={3}
                    mt="0"
                    bg="blue.100"
                    align="center"
                    justifyContent="center"
                    w={[12, 24]}
                    _groupHover={{ bg: 'blue.200', fontWeight: 'semibold' }}
                >
                    {time}
                </Flex>
                <Box
                    p="3"
                    flexGrow="1"
                    _groupHover={{ bg: 'gray.50' }}
                    w="full"
                >
                    <Text
                        mt="0"
                        textDecoration={
                            item.is_decline ? 'line-through' : 'inherit'
                        }
                    >
                        {item.name}
                    </Text>
                    {trainer && (
                        <Text mt="0" fontSize="xs" color="gray.500">
                            {trainer}
                        </Text>
                    )}
                    <Text mt="0" fontSize="xs" color="gray.500">
                        {getListMiscText(item)}
                    </Text>
                </Box>
                <Flex
                    align="center"
                    justifyContent="center"
                    px={[1, 3]}
                    color={'brand.' + color}
                    _groupHover={{ bg: 'gray.50' }}
                >
                    <AppointmentIcon color={color} />
                </Flex>
            </Flex>
        </Link>
    );
}

function AppointmentIcon({ color }) {
    // bookable
    if (color === 'green') {
        return <Icon path={mdiCalendarCheck} size={1} />;
    }

    // allready booked => ready for cancel
    if (color === 'red') {
        return <Icon path={mdiCalendarRemove} size={1} />;
    }

    // class full
    if (color === 'yellow') {
        return <Icon path={mdiCalendarText} size={1} />;
    }

    // not bookable
    return <Icon path={mdiCalendarClock} size={1} />;
}

function getListMiscText(appointment) {
    let howParticipants = getHowParticipants(appointment);
    let max = parseInt(appointment.max_participant, 0);

    if (max === 0 || appointment.max_participant === '') {
        if (howParticipants > 0) {
            return `unbegrenzte Teilnehmerzahl (${howParticipants})`;
        }
        return 'unbegrenzte Teilnehmerzahl';
    }

    if (howParticipants <= max) {
        return `${howParticipants}/${max} Teilnehmer`;
    }

    let diff = howParticipants - max;

    return `${max}/${max} Teilnehmer (${diff} Warteliste)`;
}

function getHowParticipants(appointment) {
    if (!appointment.participants) {
        return 0;
    }

    let participants = appointment.participants;

    // filter deleted users
    participants = _.filter(participants, item => {
        if (item.type !== 'MEMBER') {
            return true;
        }

        return item.user_id !== null;
    });

    // filter canceled users
    participants = _.filter(participants, item => item.canceled_at === null);

    return participants.length;
}
