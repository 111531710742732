import ActiveLink from '@/components/ActiveLink';
import { useAuthContext } from '@/components/context/authContext';
import useAppointmentCompanies from '@/components/context/useAppointmentCompanies';
import useCompany from '@/components/context/useCompany';
import useMembership from '@/components/context/useMembership';
import useUser from '@/components/context/useUser';
import getEnvironment from '@/components/environments/getEnvironment';
import AppointmentForm from '@/components/form/AppointmentForm';
import CompanyCreateRequestForm from '@/components/form/CompanyCreateRequestForm';
import CompanyRemoveRequestForm from '@/components/form/CompanyRemoveRequestForm';
import CompanyUserSettingsForm from '@/components/form/CompanyUserSettingsForm';
import hasPermission from '@/components/hasPermission';
import { hasProduct, isUserAdmin } from '@/components/helper';
import { AdminTab, Permissions } from '@/components/share/Permissions';
import AppointmentList from '@/components/ui/appointment/AppointmentList';
import Card from '@/components/ui/Card';
import CompanyHead from '@/components/ui/company/CompanyHead';
import CreateButton from '@/components/ui/CreateButton';
import Drawer from '@/components/ui/Drawer';
import Layout from '@/components/ui/Layout';
import UserStatus from '@/components/ui/user/UserStatus';
import {
    Button,
    Container,
    Grid,
    GridItem,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { mdiArrowTopRightThick, mdiWrench } from '@mdi/js';
import Icon from '@mdi/react';
import ErrorPage from 'next/error';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

const GUEST = 'GUEST';
const CREATE_REQUEST = 'CREATE_REQUEST';
const DELETE_REQUEST = 'DELETE_REQUEST';
const MEMBER = 'MEMBER';

export default function Index() {
    // prepare
    let [company, isLoadingCompany, refreshCompany] = useCompany();
    let [user, isLoadingUser] = useUser();
    let [membership, isLoadingMembership, refetchMembership] = useMembership();
    let [appointments, isLoadingAppointments, refetchAppointments] =
        useAppointmentCompanies();
    let { isAuthenticated } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // refresh
    useEffect(() => {
        refetchMembership();
        refreshCompany();
        refetchAppointments();
    }, []);

    // set permission
    let perm = useMemo(() => {
        if (!isAuthenticated()) {
            return GUEST;
        }
        if (membership === null) {
            return CREATE_REQUEST;
        }
        let s = membership.role.slug;
        let r = getEnvironment().membershipStatus.REQUEST_STARTED;
        if (s === r) {
            return DELETE_REQUEST;
        }

        return MEMBER;
    }, [membership, isAuthenticated]);

    // loading
    if (
        isLoadingCompany ||
        isLoadingMembership ||
        isLoadingUser ||
        isLoadingAppointments ||
        company === undefined
    ) {
        return (
            <Layout
                isIndex={true}
                isRaw={true}
                isExternalLoading={true}
                breadcrumb={{
                    dashboard: { isLast: true },
                }}
            ></Layout>
        );
    }

    if (company === null) {
        return <ErrorPage statusCode={404}>Company not found</ErrorPage>;
    }

    // console.log('appointments', appointments)

    return (
        <Layout
            title={company.name}
            isIndex={true}
            isRaw={true}
            checkUserAuth={false}
            onlyForGuest={false}
            onlyForAuthenticatedUser={false}
            // isExternalLoading={isLoading}
            breadcrumb={
                perm === MEMBER
                    ? {
                          dashboard: {},
                          company: company,
                      }
                    : {
                          dashboard: {},
                          search: {},
                          company: company,
                      }
            }
        >
            <Container maxW="7xl" className="maximumheight" px={4} mb="6">
                <CompanyHead
                    company={company}
                    right={
                        hasPermission(user, membership, AdminTab) && (
                            <NextLink
                                href={`/${company.id}/admin/approvalmember`}
                                passHref
                            >
                                <Button
                                    leftIcon={
                                        <Icon
                                            className="fill-current"
                                            color="gray.300"
                                            path={mdiWrench}
                                            size={0.7}
                                        />
                                    }
                                    colorScheme="blackAlpha"
                                    as="a"
                                >
                                    Administration
                                </Button>
                            </NextLink>
                        )
                    }
                />

                <Grid mt="6" templateColumns="repeat(3, 1fr)" gap={6}>
                    <GridItem colSpan={[3, 3, 2]}>
                        {(perm === CREATE_REQUEST ||
                            perm === DELETE_REQUEST) && (
                            <Card title="Mitgliedschaft">
                                {perm === CREATE_REQUEST && (
                                    <CompanyCreateRequestForm
                                        company={company}
                                        onFinished={() => {
                                            refetchMembership();
                                        }}
                                    />
                                )}
                                {perm === DELETE_REQUEST && (
                                    <CompanyRemoveRequestForm
                                        company={company}
                                        onFinished={() => {
                                            refetchMembership();
                                        }}
                                    />
                                )}
                            </Card>
                        )}

                        {perm === GUEST && (
                            <Card title="Anmelden">
                                <Text textAlign="left">
                                    Um einen Termin zu buchen musst du dich
                                    einloggen.
                                </Text>
                                <ActiveLink
                                    href="/login"
                                    activeClassName="active"
                                    includePath={false}
                                >
                                    <Button
                                        mt="4"
                                        leftIcon={
                                            <Icon
                                                path={mdiArrowTopRightThick}
                                                size={1}
                                            />
                                        }
                                        variant="solid"
                                        as="a"
                                        cursor="pointer"
                                        className="btn-header"
                                    >
                                        Login
                                    </Button>
                                </ActiveLink>
                            </Card>
                        )}

                        {(perm === MEMBER || isUserAdmin(user)) && (
                            <Card
                                title="Termine"
                                headRight={
                                    hasPermission(
                                        user,
                                        membership,
                                        Permissions.APPOINTMENT_CREATE,
                                    ) && <CreateButton onClick={onOpen} />
                                }
                            >
                                <AppointmentList
                                    membership={membership}
                                    appointments={appointments}
                                    // company={company}
                                    // user={user}
                                    // refresh={refreshAppointments}
                                />
                            </Card>
                        )}
                    </GridItem>

                    <GridItem colSpan={[3, 3, 1]}>
                        {hasProduct(membership) && (
                            <Card title="Mitgliedschaft" mb="6">
                                <UserStatus membership={membership} />
                            </Card>
                        )}

                        <Card title="Beschreibung">
                            <p>{company.description}</p>
                        </Card>

                        {perm === MEMBER && (
                            <Card title="Einstellungen" mt="6">
                                <CompanyUserSettingsForm
                                    membership={membership}
                                    isLoadingMembership={isLoadingMembership}
                                    refetchMembership={refetchMembership}
                                />
                            </Card>
                        )}
                    </GridItem>
                </Grid>

                {(perm === MEMBER || isUserAdmin(user)) && (
                    <Drawer
                        title="Termin erstellen"
                        isOpen={isOpen}
                        onClose={onClose}
                    >
                        <AppointmentForm
                            isCreate={true}
                            onFinished={() => {
                                onClose();
                                refetchAppointments();
                            }}
                        />
                    </Drawer>
                )}
            </Container>
        </Layout>
    );
}
