import React from 'react';
import {
    Flex,
    // FormControl,
    FormLabel,
    FormErrorMessage,
    Switch,
} from '@chakra-ui/react';
import _ from 'lodash';

export default function SettingsField({
    id,
    label,
    initValue,
    onAlreadyChanged,
}) {
    let [value, setValue] = React.useState(initValue);

    return (
        <Flex
            justify="space-between"
            mt="6"
            // align="center"
            // wrap="wrap"
            // alignItems="center"
        >
            <FormLabel htmlFor={id} mb="0">
                {label}
            </FormLabel>
            <Switch
                colorScheme="green"
                id={id}
                isChecked={value}
                onChange={() => {
                    let newValue = !value;
                    setValue(newValue);
                    onAlreadyChanged(id, newValue);
                }}
            />
        </Flex>
    );
}
