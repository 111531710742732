import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import useAppointments from './useAppointments';

export default function useAppointmentCompanies() {
    const router = useRouter();
    let { companyId } = router.query;
    let date = dayjs().startOf('hour').toDate();

    let filter = {
        where: {
            company_id: {
                _eq: companyId,
            },
            appointment: { _gte: date },
            status: {
                _eq: 'ACTIVE',
            },
        },
    };

    let [appointments, loading, refetch, error] = useAppointments(filter);
    return [appointments, loading, refetch, error];
}
