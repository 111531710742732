import getEnvironment from '@/components/environments/getEnvironment';
import { appointmentTransform } from '@/components/helperModel';
import dayjs from 'dayjs';
import _ from 'lodash';
import firebaseApp from '@/components/firebase/firebaseApp';
import {
    collection,
    where,
    query,
    getDocs,
    getFirestore,
    orderBy,
} from '@firebase/firestore';

module.exports = async (companyId, hideNotBookableAppointments = false) => {
    let filterDate = dayjs();
    if (hideNotBookableAppointments) {
        filterDate = filterDate.startOf('hour').toDate();
    } else {
        filterDate = filterDate.startOf('day').toDate();
    }

    const db = getFirestore(firebaseApp);

    const q = query(
        collection(
            db,
            getEnvironment().schema.companies,
            companyId,
            getEnvironment().schema.appointments,
        ),
        where('appointment', '>=', filterDate),
        where('status', '==', getEnvironment().appointmentStatus.ACTIVE),
        orderBy('appointment'),
    );

    const querySnapshot = await getDocs(q);

    let result = {};

    querySnapshot.forEach(doc => {
        result[doc.id] = appointmentTransform(doc.data());
    });

    // filter only bookable appointments
    if (hideNotBookableAppointments) {
        let filtered = {};

        _.forEach(result, (item, key) => {
            if (
                dayjs(item.bookable_from_date).diff(new Date(), 'minutes') <= 0
            ) {
                filtered[key] = item;
            }
        });

        result = filtered;
    }

    return result;
};
