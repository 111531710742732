import getEnvironment from '@/components/environments/getEnvironment';
import firebaseApp from '@/components/firebase/firebaseApp';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';

module.exports = async (user, companyId, settingId, value) => {
    // prepare
    let updateData = {};
    updateData[`companies.${companyId}.settings.${settingId}`] = value;

    // update database
    const db = getFirestore(firebaseApp);

    const docRef = doc(db, getEnvironment().schema.users, user.id);

    await updateDoc(docRef, updateData);
};
