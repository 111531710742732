import { toTextDate } from '@/components/helper';
import _ from 'lodash';

export default function PrepareItemsForList(items, order = 'asc') {
    // copy item to transform
    items = _.cloneDeep(items);

    // set group title
    items.map(appointment => {
        appointment['dateString'] = toTextDate(
            appointment.appointment,
            'dddd, LL',
            'de',
            true,
        );
    });

    // sort
    items = _.orderBy(items, ['appointment', 'name'], [order]);

    // grouping
    items = _.groupBy(items, 'dateString');

    // put the item in subarray each group
    items = _.reduce(
        items,
        (acc, next, index) => {
            acc.push({
                title: index,
                data: next,
            });
            return acc;
        },
        [],
    );

    return items;
}
